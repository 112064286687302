import React from 'react';
import MarketCapFilter from './MarketCapFilter';
import PropTypes from 'prop-types';

const FilterHeaderIcons = ({ activeFilters, onApplyFilters }) => {
  return (
    <div className="flex items-center gap-2">
      <MarketCapFilter 
        activeFilters={activeFilters?.marketCap}
        onApplyFilters={onApplyFilters}
      />
      {/* ... other icons ... */}
    </div>
  );
};

FilterHeaderIcons.propTypes = {
  activeFilters: PropTypes.shape({
    marketCap: PropTypes.arrayOf(PropTypes.string)
  }),
  onApplyFilters: PropTypes.func.isRequired
};

export default FilterHeaderIcons; 