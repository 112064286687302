import React from 'react';

const ProfileModal = ({ loggedInUser, onDeleteProfile }) => {
  return (
    <div className="profile-modal-content">
      <h3 className="profile-modal-title">Account Information</h3>
      <p className="profile-modal-email">Logged in as: {loggedInUser}</p>
      <a 
        href="https://billing.stripe.com/p/login/00g9Er4TpfdqdHOcMM"
        target="_blank"
        rel="noopener noreferrer"
        className="profile-modal-button profile-modal-button-blue"
      >
        Manage Subscription
      </a>
      <button
        onClick={onDeleteProfile}
        className="profile-modal-button profile-modal-button-red"
      >
        Delete Profile and Watchlist Data
      </button>
    </div>
  );
};

export default ProfileModal;