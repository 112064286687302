// services/api.js
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import CONFIG from "../config/config";

// Helper functions
const isValidDate = (date) => !isNaN(Date.parse(date));

const estimateNextEarningsDate = (lastEarningsDate) => {
  if (!lastEarningsDate) return null;
  const lastDate = new Date(lastEarningsDate);
  return new Date(lastDate.setMonth(lastDate.getMonth() + 3))
    .toISOString()
    .split("T")[0];
};

// Consolidated screen data fetch
export const fetchScreenData = async (screen, userEmail, customSymbols = null) => {
  let url = `${CONFIG.API_URLS.SCREEN_DATA}`;
  const params = new URLSearchParams();

  if (customSymbols) {
    params.append('symbols', customSymbols.join(','));
  } else {
    params.append('screen', screen);
    if (screen === "watchlist" && userEmail) {
      params.append('userEmail', userEmail);
    }
  }

  url += `?${params.toString()}`;
  console.log("Fetching screen data from URL:", url);

  try {
    const response = await axios.get(url);
    console.log("Raw API response:", response);
    const screenData = response.data;
    console.log("Raw screen data:", screenData);

    if (!screenData || !screenData.stocks || screenData.stocks.length === 0) {
      console.error("No stocks data found in the API response");
      return [];
    }

    // Process the data
    const processedData = screenData.stocks.map((stock) => ({
      name: stock.companyName,
      tickerSymbol: stock.symbol,
      website: stock.website,
      fullTimeEmployees: stock.fullTimeEmployees,
      industry: stock.industry,
      overallRisk: stock.overallRisk,
      description: stock.description,
      earningsDate: stock.next_earnings_date,
      isEarningsDateEstimated: stock.next_earnings_date_estimated === 1,
      stockPrice: stock.current_price != null && !isNaN(stock.current_price)
        ? parseFloat(stock.current_price)
        : null,
      stockChange: stock.percent_change ? parseFloat(stock.percent_change) : null,
      positions: stock.glassdoor_job_count?.toString() || "N/A",
      positionsPercentage: stock.glassdoor_job_count && stock.fullTimeEmployees
        ? (stock.glassdoor_job_count / stock.fullTimeEmployees) * 100
        : null,
      outlook: stock.glassdoor_business_outlook_rating?.toString() || "N/A",
      logo_url: stock.logo_url || null,
      trafficData: stock.trafficData || [],
      trafficChange: calculateTrafficChange(stock.trafficData),
      currentTraffic: stock.trafficData?.[0]?.visits || null,
      lastEarningsDate: stock.last_earnings_date,
      ai_analysis: stock.ai_analysis || null,
      // New fields from current_price_cache
      regularMarketChange: stock.regular_market_change,
      regularMarketChangePercent: stock.regular_market_change_percent != null
        ? parseFloat(stock.regular_market_change_percent)
        : null,
      regularMarketTime: stock.regular_market_time,
      regularMarketDayHigh: stock.regular_market_day_high,
      regularMarketDayLow: stock.regular_market_day_low,
      regularMarketVolume: stock.regular_market_volume,
      regularMarketPreviousClose: stock.regular_market_previous_close,
      preMarketPrice: stock.pre_market_price,
      preMarketChange: stock.pre_market_change,
      preMarketChangePercent: stock.pre_market_change_percent,
      postMarketPrice: stock.post_market_price,
      postMarketChange: stock.post_market_change,
      postMarketChangePercent: stock.post_market_change_percent,
      fiftyTwoWeekLow: stock.fifty_two_week_low,
      fiftyTwoWeekHigh: stock.fifty_two_week_high,
      fiftyTwoWeekChangePercent: stock.fifty_two_week_change_percent,
      averageVolume3Month: stock.average_volume_3month,
      averageVolume10Day: stock.average_volume_10day,
      fiftyDayAverage: stock.fifty_day_average,
      fiftyDayAverageChange: stock.fifty_day_average_change,
      fiftyDayAverageChangePercent: stock.fifty_day_average_change_percent,
      twoHundredDayAverage: stock.two_hundred_day_average,
      twoHundredDayAverageChange: stock.two_hundred_day_average_change,
      twoHundredDayAverageChangePercent: stock.two_hundred_day_average_change_percent,
      fiftyTwoWeekLowChange: stock.fifty_two_week_low_change,
      fiftyTwoWeekLowChangePercent: stock.fifty_two_week_low_change_percent,
      fiftyTwoWeekHighChange: stock.fifty_two_week_high_change,
      fiftyTwoWeekHighChangePercent: stock.fifty_two_week_high_change_percent,
      // Fields from stock_financial_info
      currency: stock.currency,
      quoteType: stock.quote_type,
      shortName: stock.short_name,
      longName: stock.long_name,
      marketCap: stock.market_cap,
      bookValue: stock.book_value,
      sharesOutstanding: stock.shares_outstanding,
      averageAnalystRating: stock.average_analyst_rating,
      forwardPE: stock.forward_pe,
      trailingPE: stock.trailing_pe,
      priceToBook: stock.price_to_book,
      trailingAnnualDividendRate: stock.trailing_annual_dividend_rate,
      trailingAnnualDividendYield: stock.trailing_annual_dividend_yield,
      epsTrailingTwelveMonths: stock.eps_trailing_twelve_months,
      epsForward: stock.eps_forward,
      trailingPegRatio: stock.trailing_peg_ratio,
      dividendRate: stock.dividend_rate,
      dividendYield: stock.dividend_yield,
      financialInfoLastUpdated: stock.financial_info_last_updated,
      // Financial Stats fields
      financialStatsLastUpdated: stock.financial_stats_last_updated,
      shortPercentOfFloat: stock.short_percent_of_float != null ? parseFloat(stock.short_percent_of_float) : null,
      enterpriseToRevenue: stock.enterprise_to_revenue,
      enterpriseToEbitda: stock.enterprise_to_ebitda,
      fiftyTwoWeekChange: stock.fifty_two_week_change,
      sandpFiftyTwoWeekChange: stock.sandp_fifty_two_week_change,
      pegRatio: stock.peg_ratio != null ? parseFloat(stock.peg_ratio) : null,
      revenueGrowth: stock.revenue_growth != null ? parseFloat(stock.revenue_growth) : null,
      quickRatio: stock.quick_ratio,
      currentRatio: stock.current_ratio,
      grossMargins: stock.gross_margins != null ? parseFloat(stock.gross_margins) : null,
      operatingMargins: stock.operating_margins != null ? parseFloat(stock.operating_margins) : null,
      totalCashPerShare: stock.total_cash_per_share,
      targetHighPrice: stock.target_high_price,
      targetLowPrice: stock.target_low_price,
      targetMeanPrice: stock.target_mean_price,
      beta: stock.beta != null ? parseFloat(stock.beta) : null,
      priceToSalesTrailing12Months: stock.price_to_sales_trailing_12_months,
      trailingEps: stock.trailing_eps,
      forwardEps: stock.forward_eps,
      
      // Keep the original field names from the API
      peg_ratio: stock.peg_ratio,
      revenue_growth: stock.revenue_growth,
      short_percent_of_float: stock.short_percent_of_float,
      gross_margins: stock.gross_margins,
      operating_margins: stock.operating_margins,
      beta: stock.beta,
      
      // Add debug logging
      ...(process.env.NODE_ENV === 'development' && {
        _debug_margins: {
          gross: stock.gross_margins,
          operating: stock.operating_margins
        }
      })
    }));

    console.log(`Processed screen data for ${screen || 'custom symbols'}:`, processedData);
    return processedData;
  } catch (error) {
    console.error(`Error fetching screen data for ${screen || 'custom symbols'}:`, error);
    throw error;
  }
};

const calculateTrafficChange = (trafficData) => {
  if (!trafficData || trafficData.length < 2) return null;
  const sortedData = [...trafficData].sort((a, b) => new Date(b.trafficDate) - new Date(a.trafficDate));
  const currentTraffic = sortedData[0].visits;
  const previousTraffic = sortedData[sortedData.length - 1].visits;
  return ((currentTraffic - previousTraffic) / previousTraffic) * 100;
};

export const useWatchlistData = (email) => {
  return useQuery({
    queryKey: ["watchlistData", email],
    queryFn: async () => {
      if (!email) return [];
      try {
        const data = await fetchScreenData("watchlist", email);
        console.log('Raw watchlist data from API:', data);
        return data.stocks || data || [];
      } catch (error) {
        console.error('Error fetching watchlist data:', error);
        return [];
      }
    },
    enabled: !!email,
    retry: 3,
    retryDelay: 1000,
  });
};

export const updateWatchlist = async (email, symbols) => {
  try {
    const response = await axios.post(`${CONFIG.API_URLS.USER_DATA}`, {
      userId: email,
      email: email,
      companiesSymbols: symbols,
      firstUseTimestamp: localStorage.getItem('firstUseTimestamp') || new Date().toISOString()
    });
    console.log('Watchlist update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating watchlist:', error);
    throw error;
  }
};

export const useEconomicData = (geography) => {
  return useQuery({
    queryKey: ["economicData", geography],
    queryFn: () => fetchEconomicData(geography),
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep unused data in cache for 30 minutes
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    retry: 3, // Retry failed requests 3 times
    onError: (error) => {
      console.error("Error fetching economic data:", error);
      // You can implement more robust error handling here if needed
    },
  });
};

export const useMarketAnalysis = (forceRefresh = false) => {
  return useQuery({
    queryKey: ['marketAnalysis', forceRefresh],
    queryFn: async () => {
      const today = new Date().toISOString().split('T')[0];
      const response = await axios.get(`https://market_news_2.drisw.workers.dev/?date=${today}&category=239&forceRefresh=${forceRefresh}`);
      console.log('Raw Market Analysis API Response:', response.data);
      return response.data;
    },
    refetchInterval: 60 * 60 * 1000, // Refetch every hour
    staleTime: 60 * 60 * 1000, // Consider data fresh for 1 hour
    cacheTime: 2 * 60 * 60 * 1000, // Keep unused data in cache for 2 hours
  });
};

const fetchEconomicData = async (geography = "US") => {
  const url = `${CONFIG.API_URLS.ECONOMIC_CALENDAR}?timeframe=-1,0,1&geography=${geography}`;
  console.log("Fetching economic data from:", url);
  try {
    const response = await axios.get(url);
    if (!response.data || typeof response.data !== "object") {
      throw new Error("Invalid economic data received");
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching economic data:`, error.message);
    throw error;
  }
};

export const updateUserData = async (email, data) => {
  if (!email) {
    throw new Error('Email is required');
  }

  // Build the payload carefully
  const payload = {
    email,
    preferences: data.preferences,
    // Include other fields if they have values
    ...(data.companiesSymbols ? { companiesSymbols: data.companiesSymbols } : {}),
    ...(data.name ? { name: data.name } : {}),
    ...(data.firstUseTimestamp ? { firstUseTimestamp: data.firstUseTimestamp } : {}),
  };

  try {
    const response = await fetch(CONFIG.API_URLS.USER_DATA, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    console.log('Server response:', responseData);

    if (!response.ok) {
      throw new Error(responseData.error || 'Failed to update user data');
    }

    return responseData;
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};

export const useCategories = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      try {
        const response = await axios.get('https://screen_query.drisw.workers.dev/available-screens');
        return response.data;
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        throw error;
      }
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};
