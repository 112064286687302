// config/config.js
const CONFIG = {
    CACHE_EXPIRY: {
      STOCK_PERFORMANCE: 3600000, // 1 hour
      ECONOMIC_DATA: 86400000, // 1 day
      TRAFFIC_DATA: 3600000, // 1 hour
      COMPANY_DATA: 382400000, // 1 week
      MEMORY_CACHE: 7200000, // 2 hours
    },
    API_URLS: {
      STOCK_PERFORMANCE: 'https://seeking-alpha.drisw.workers.dev/?ticker=',
      COMPANY_PROFILE: 'https://company-profile.drisw.workers.dev/?tickers=',
      WEB_APP_TRAFFIC: 'https://web-app-traffic-cached.drisw.workers.dev/fetchTraffic?domain=',
      GLASSDOOR: 'https://glassdoor.drisw.workers.dev/?query=',
      ECONOMIC_CALENDAR: 'https://econ_cal_new.drisw.workers.dev/',
      SCREENER: 'https://screener.drisw.workers.dev/?list=',
      USER_DATA: 'https://fp_user.drisw.workers.dev/api/user-data',
      EARNINGS_ANALYTICS: 'https://earnings-analytics.drisw.workers.dev/',
      SCREEN_DATA: 'https://screen_query.drisw.workers.dev/screen-data',
      DAILY_MOVEMENT_ANALYTICS: 'https://daily-movement-analytics.drisw.workers.dev'
    },
    API_PARAMS: {
      ECONOMIC_DATA: {
        COUNTRY_CODE: 'US',
        DAYS_BACK: 5,
        DAYS_FORWARD: 10,
      },
    },
  };
  
  export default CONFIG;
  