import React from 'react';

const StatusHeader = ({ message, type }) => {
  const getStyles = () => {
    switch (type) {
      case 'info':
        return 'border-blue-500 text-red-600';
      case 'success':
        return 'border-green-500 text-red-600';
      case 'warning':
        return 'border-yellow-500 text-red-600';
      case 'error':
        return 'border-red-500 text-red-600';
      default:
        return 'border-gray-500 text-red-600';
    }
  };

  const getEmojis = () => {
    switch (type) {
      case 'info':
        return ['ℹ️', '🚀'];
      case 'success':
        return ['✅', '🎉'];
      case 'warning':
        return ['⚠️', '🔔'];
      case 'error':
        return ['❌', '🚨'];
      default:
        return ['🔍', '💡'];
    }
  };

  const [startEmoji, endEmoji] = getEmojis();

  return (
    <div className={`border-b-2 ${getStyles()} text-center py-2 px-4 text-sm font-medium`}>
      {startEmoji} {message} {endEmoji}
    </div>
  );
};

export default StatusHeader;
