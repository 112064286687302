import React, { useState, useEffect, useMemo } from 'react';

const TradingRangeInsights = ({ stock }) => {
  // Extract necessary data from stock
  const {
    stockPrice, // Current price (string)
    fiftyTwoWeekLow,
    fiftyTwoWeekHigh,
    fiftyDayAverage,
    twoHundredDayAverage,
    regularMarketVolume,
    averageVolume3Month,
    averageVolume10Day,
  } = stock;

  // Convert string values to numbers
  const currentPrice = parseFloat(stockPrice);
  const priceMin = parseFloat(fiftyTwoWeekLow);
  const priceMax = parseFloat(fiftyTwoWeekHigh);
  const fiftyDayAvg = parseFloat(fiftyDayAverage);
  const twoHundredDayAvg = parseFloat(twoHundredDayAverage);
  const volumeCurrent = parseFloat(regularMarketVolume);
  const volumeAvg3M = parseFloat(averageVolume3Month);
  const volumeAvg10D = parseFloat(averageVolume10Day);

  // Adjust price range to add a buffer to the left of the minimum value
  const adjustedPriceMin = priceMin - (priceMax - priceMin) * 0.05;
  const adjustedPriceMax = priceMax;

  // Adjust volume range to start at the lowest value instead of 0
  const volumeMin = Math.min(volumeCurrent, volumeAvg3M, volumeAvg10D);
  const adjustedVolumeMin = !isNaN(volumeMin) && volumeMin > 0 ? volumeMin * 0.95 : 1;

  // Validate data
  const isPriceDataValid =
    !isNaN(priceMin) && !isNaN(priceMax) && !isNaN(currentPrice);
  const isVolumeDataValid =
    !isNaN(volumeCurrent) &&
    (!isNaN(volumeAvg3M) || !isNaN(volumeAvg10D));

  // Hooks must be called before any returns
  const [priceMarkerPositions, setPriceMarkerPositions] = useState([]);
  const [volumeMarkerPositions, setVolumeMarkerPositions] = useState([]);
  const [interpretation, setInterpretation] = useState('');
  const [badgeType, setBadgeType] = useState('');

  // Styles used in useEffect
  const currentMarkerStyle = useMemo(
    () => ({
      width: '20px',
      height: '20px',
      backgroundColor: '#3b82f6', // blue-500
      borderRadius: '50%',
    }),
    []
  );

  const averageMarkerStyle = useMemo(
    () => ({
      width: '20px',
      height: '20px',
      backgroundColor: '#10b981', // green-500
      clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
    }),
    []
  );

  // Helper function to calculate position percentage
  const calculateAdjustedPosition = (value, min, max) => {
    if (max === min) return 0;
    const normalizedValue = (value - min) / (max - min);
    return normalizedValue * 100;
  };

  // For Price range
  const priceAverages = useMemo(
    () =>
      [
        { label: '50D Avg', value: fiftyDayAvg },
        { label: '200D Avg', value: twoHundredDayAvg },
      ].filter((avg) => !isNaN(avg.value)),
    [fiftyDayAvg, twoHundredDayAvg]
  );

  // For Volume range
  const volumeAverages = useMemo(
    () =>
      [
        { label: '3M Avg', value: volumeAvg3M },
        { label: '10D Avg', value: volumeAvg10D },
      ].filter((avg) => !isNaN(avg.value)),
    [volumeAvg3M, volumeAvg10D]
  );

  const volumeMax = Math.max(
    volumeCurrent || 0,
    ...volumeAverages.map((avg) => avg.value)
  );

  const calculateMarkerPositions = (markers) => {
    const sortedMarkers = markers.sort((a, b) => a.position - b.position);
    const minSpacing = 5; // Minimum horizontal spacing in percentage
    let lastPosition = -Infinity;
    let verticalOffset = 0;

    return sortedMarkers.map((marker) => {
      if (marker.position - lastPosition < minSpacing) {
        verticalOffset = (verticalOffset + 1) % 3; // Cycle through 3 vertical positions
      } else {
        verticalOffset = 0;
      }
      lastPosition = marker.position;
      return { ...marker, verticalOffset };
    });
  };

  // Helper functions for analysis
  const analyzePriceAndVolume = (currentPrice, fiftyDayAvg, twoHundredDayAvg, currentVolume, avgVolume10D, avgVolume3M) => {
    const priceChange = currentPrice - fiftyDayAvg;
    const shortTermVsLongTermPrice = fiftyDayAvg / twoHundredDayAvg;
    const shortTermVsLongTermVolume = avgVolume10D / avgVolume3M;
    const currentVsShortTermVolume = currentVolume / avgVolume10D;
    const currentVsLongTermVolume = currentVolume / avgVolume3M;

    let insights = [];

    // Price trend analysis
    if (shortTermVsLongTermPrice > 1.05) {
      insights.push("The 50-day moving average is above the 200-day moving average, indicating a potential bullish trend.");
    } else if (shortTermVsLongTermPrice < 0.95) {
      insights.push("The 50-day moving average is below the 200-day moving average, indicating a potential bearish trend.");
    }

    // Volume trend analysis
    if (shortTermVsLongTermVolume < 0.9) {
      insights.push("Trading volume has been decreasing over the past 10 days compared to the 3-month average, suggesting waning interest.");
    } else if (shortTermVsLongTermVolume > 1.1) {
      insights.push("Trading volume has been increasing over the past 10 days compared to the 3-month average, indicating growing interest.");
    }

    // Current price and volume analysis
    if (currentVsShortTermVolume > 1.5 && currentVsLongTermVolume > 1.5) {
      if (priceChange > 0) {
        insights.push("The stock is up on significantly higher volume than recent averages, suggesting strong buying pressure.");
      } else {
        insights.push("The stock is down on significantly higher volume than recent averages, potentially indicating increased selling pressure.");
      }
    } else if (currentVsShortTermVolume < 0.7 && currentVsLongTermVolume < 0.7) {
      if (priceChange > 0) {
        insights.push("The stock is up on significantly lower volume than recent averages, which might indicate a lack of conviction in the move.");
      } else {
        insights.push("The stock is down on significantly lower volume than recent averages, potentially suggesting a lack of selling pressure.");
      }
    }

    // Conclusion
    let conclusion = "";
    if (priceChange > 0 && shortTermVsLongTermVolume > 1) {
      conclusion = "The combination of rising price and increasing volume suggests growing investor interest and could indicate further upside potential.";
    } else if (priceChange < 0 && shortTermVsLongTermVolume < 1) {
      conclusion = "The combination of falling price and decreasing volume might suggest a lack of buying support, potentially leading to further price declines.";
    } else if (priceChange > 0 && shortTermVsLongTermVolume < 1) {
      conclusion = "Despite the price increase, the decreasing volume suggests this move may lack conviction and could be unsustainable.";
    } else if (priceChange < 0 && shortTermVsLongTermVolume > 1) {
      conclusion = "The increasing volume on price declines could indicate capitulation or strong selling pressure, but might also set the stage for a potential reversal.";
    }

    return insights.join(" ") + (conclusion ? " " + conclusion : "");
  };

  const determineBadgeType = (currentPrice, fiftyDayAvg, twoHundredDayAvg, volumeCurrent, volumeAvg10D, volumeAvg3M) => {
    const priceChange = currentPrice - fiftyDayAvg;
    const shortTermVsLongTermPrice = fiftyDayAvg / twoHundredDayAvg;
    const shortTermVsLongTermVolume = volumeAvg10D / volumeAvg3M;
    const currentVsShortTermVolume = volumeCurrent / volumeAvg10D;

    if (shortTermVsLongTermPrice > 1.05 && shortTermVsLongTermVolume > 1.05) {
      return "Bullish Trend";
    } else if (shortTermVsLongTermPrice < 0.95 && shortTermVsLongTermVolume < 0.95) {
      return "Bearish Trend";
    } else if (priceChange > 0 && currentVsShortTermVolume > 1.5) {
      return "Potential Breakout";
    } else if (priceChange < 0 && currentVsShortTermVolume > 1.5) {
      return "Potential Breakdown";
    } else if ((priceChange > 0 && shortTermVsLongTermVolume < 0.95) || 
               (priceChange < 0 && shortTermVsLongTermVolume > 1.05)) {
      return "Potential Reversal";
    }
    return "Neutral";
  };

  // useEffect Hook
  useEffect(() => {
    const priceMarkers = [
      {
        label: 'Current',
        value: currentPrice,
        position: calculateAdjustedPosition(currentPrice, adjustedPriceMin, adjustedPriceMax),
        markerStyle: currentMarkerStyle,
      },
      ...priceAverages.map((avg) => ({
        label: avg.label,
        value: avg.value,
        position: calculateAdjustedPosition(avg.value, adjustedPriceMin, adjustedPriceMax),
        markerStyle: averageMarkerStyle,
      })),
    ];

    // Updated Volume calculations
    const volumeValues = [volumeCurrent, volumeAvg10D, volumeAvg3M].filter(v => !isNaN(v));
    const volumeMin = Math.min(...volumeValues);
    const volumeMax = Math.max(...volumeValues);

    const volumeMarkers = [
      {
        label: 'Current',
        value: volumeCurrent,
        position: calculateAdjustedPosition(volumeCurrent, volumeMin, volumeMax),
        markerStyle: currentMarkerStyle,
      },
      {
        label: '10D Avg',
        value: volumeAvg10D,
        position: calculateAdjustedPosition(volumeAvg10D, volumeMin, volumeMax),
        markerStyle: averageMarkerStyle,
      },
      {
        label: '3M Avg',
        value: volumeAvg3M,
        position: calculateAdjustedPosition(volumeAvg3M, volumeMin, volumeMax),
        markerStyle: averageMarkerStyle,
      },
    ].filter(marker => !isNaN(marker.value));

    setPriceMarkerPositions(calculateMarkerPositions(priceMarkers));
    setVolumeMarkerPositions(calculateMarkerPositions(volumeMarkers));

    const combinedAnalysis = analyzePriceAndVolume(
      currentPrice,
      fiftyDayAvg,
      twoHundredDayAvg,
      volumeCurrent,
      volumeAvg10D,
      volumeAvg3M
    );
    setInterpretation(combinedAnalysis || "Price and volume patterns are within normal ranges without significant implications.");

    const badge = determineBadgeType(
      currentPrice,
      fiftyDayAvg,
      twoHundredDayAvg,
      volumeCurrent,
      volumeAvg10D,
      volumeAvg3M
    );
    setBadgeType(badge);
  }, [
    currentPrice,
    adjustedPriceMin,
    adjustedPriceMax,
    priceAverages,
    currentMarkerStyle,
    averageMarkerStyle,
    volumeCurrent,
    adjustedVolumeMin,
    volumeMax,
    volumeAverages,
    fiftyDayAvg,
    twoHundredDayAvg,
    volumeAvg3M,
    priceMin,
    priceMax,
    volumeAvg10D
  ]);

  // Styles used in the component
  const containerStyle = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    padding: '24px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  };

  const graphColumnStyle = {
    width: '63%', // Slightly reduced to add more space
  };

  const insightColumnStyle = {
    width: '32%',
    marginLeft: '5%', // Add space between graphs and insights
  };

  const insightBoxStyle = {
    backgroundColor: '#f3f4f6',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
  };

  const insightTitleStyle = {
    fontSize: '1rem',
    fontWeight: '600',
    marginBottom: '8px',
    color: '#4a5568',
  };

  const insightTextStyle = {
    fontSize: '0.9rem',
    color: '#4a5568',
    lineHeight: '1.4',
  };

  const placeholderStyle = {
    ...insightTextStyle,
    fontStyle: 'italic',
    color: '#a0aec0',
  };

  const titleStyle = {
    fontSize: '1.25rem',
    fontWeight: '600',
    marginBottom: '16px',
  };

  const badgeStyle = {
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: '4px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    marginBottom: '8px',
  };

  const getBadgeColor = (type) => {
    switch (type) {
      case 'Bullish Trend': return { backgroundColor: '#34D399', color: '#064E3B' };
      case 'Bearish Trend': return { backgroundColor: '#F87171', color: '#7F1D1D' };
      case 'Potential Breakout': return { backgroundColor: '#60A5FA', color: '#1E3A8A' };
      case 'Potential Breakdown': return { backgroundColor: '#FCD34D', color: '#92400E' };
      case 'Potential Reversal': return { backgroundColor: '#A78BFA', color: '#4C1D95' };
      default: return { backgroundColor: '#E5E7EB', color: '#374151' };
    }
  };

  // Early return after Hooks and styles
  if (!isPriceDataValid && !isVolumeDataValid) {
    return (
      <div style={containerStyle}>
        <h3 style={titleStyle}>Trading Range Analysis</h3>
        <p>Insufficient data to display trading range insights.</p>
      </div>
    );
  }

  // Helper function to format numbers
  const formatNumber = (num) => {
    if (num >= 1e9) return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
    if (num >= 1e6) return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    return num.toString();
  };

  // Rest of styles
  const subtitleStyle = {
    fontSize: '1rem',
    fontWeight: '600',
    marginBottom: '8px',
  };

  const rangeContainerStyle = {
    position: 'relative',
    height: '80px',
    marginBottom: '24px',
    width: '100%',
    padding: '0 10px', // Add padding for endpoints
  };

  const rangeLineStyle = {
    position: 'absolute',
    left: '10px',
    right: '10px',
    top: '50%',
    height: '2px',
    backgroundColor: '#cbd5e0',
  };

  const markerBaseStyle = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const circleStyle = {
    width: '8px',
    height: '8px',
    backgroundColor: 'black',
    borderRadius: '50%',
  };

  const labelStyle = {
    fontSize: '0.65rem',
    whiteSpace: 'nowrap',
    position: 'absolute',
    textAlign: 'center',
  };

  const connectorLineStyle = {
    position: 'absolute',
    width: '1px',
    backgroundColor: '#cbd5e0',
    bottom: '100%',
  };

  const renderRangeBar = (markers, isVolume = false) => (
    <div style={rangeContainerStyle}>
      <div style={rangeLineStyle}></div>

      {/* Min and Max Markers - only for Price Range */}
      {!isVolume && (
        <>
          <div style={{ ...markerBaseStyle, left: '10px' }}>
            <div style={circleStyle}></div>
            <div style={{...labelStyle, top: '100%', marginTop: '4px'}}>
              52W Low<br />
              {priceMin.toFixed(2)}
            </div>
          </div>
          <div style={{ ...markerBaseStyle, right: '10px', left: 'auto' }}>
            <div style={circleStyle}></div>
            <div style={{...labelStyle, top: '100%', marginTop: '4px'}}>
              52W High<br />
              {priceMax.toFixed(2)}
            </div>
          </div>
        </>
      )}

      {/* Other Markers */}
      {markers.map((marker, index) => (
        <div
          key={index}
          style={{
            ...markerBaseStyle,
            left: `calc(${marker.position}% + 10px)`,
            top: `calc(50% + ${marker.verticalOffset * 15}px)`,
          }}
        >
          <div style={marker.markerStyle}></div>
          <div style={{
            ...labelStyle,
            ...(isVolume && marker.label !== 'Current'
              ? { top: '100%', marginTop: '4px' }
              : marker.verticalOffset === 0
                ? { bottom: '100%', marginBottom: '4px' }
                : { top: '100%', marginTop: '4px' }
            ),
          }}>
            {marker.label} {isVolume ? formatNumber(marker.value) : marker.value.toFixed(2)}
          </div>
        </div>
      ))}
    </div>
  );

  const getPlaceholderText = (type) => {
    switch (type) {
      case 'price':
        return "No significant price patterns detected. The stock is trading within its normal range.";
      case 'volume':
        return "Current trading volume is within normal levels compared to recent averages.";
      default:
        return "No significant patterns detected at this time.";
    }
  };

  // Component Render
  return (
    <div style={containerStyle}>
      <div style={graphColumnStyle}>
        <h3 style={titleStyle}>Trading Range Analysis</h3>

        {/* Price Range Bar */}
        {isPriceDataValid && (
          <div>
            <h4 style={subtitleStyle}>Price Range</h4>
            {renderRangeBar(priceMarkerPositions)}
          </div>
        )}

        {/* Volume Range Bar */}
        {isVolumeDataValid && (
          <div>
            <h4 style={subtitleStyle}>Volume Range</h4>
            {renderRangeBar(volumeMarkerPositions, true)}
          </div>
        )}
      </div>

      <div style={insightColumnStyle}>
        <h3 style={titleStyle}>Insights</h3>
        {badgeType && (
          <div style={{...badgeStyle, ...getBadgeColor(badgeType)}}>
            {badgeType}
          </div>
        )}
        <div style={insightBoxStyle}>
          <h4 style={insightTitleStyle}>Price and Volume Analysis</h4>
          <p style={interpretation ? insightTextStyle : placeholderStyle}>
            {interpretation}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TradingRangeInsights;
