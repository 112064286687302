import React from 'react';
import './EconomicEventControls.css';

const EconomicEventControls = ({ 
  selectedTimeframes,
  setSelectedTimeframes,
  geography,
  setGeography,
  showEconomicEvents,
  setShowEconomicEvents
}) => {
  const handleTimeframeChange = (value) => {
    setSelectedTimeframes(prevTimeframes => {
      if (prevTimeframes.includes(value)) {
        return prevTimeframes.filter(t => t !== value);
      } else {
        return [...prevTimeframes, value];
      }
    });
    // Ensure economic events are shown when a timeframe is selected
    if (!showEconomicEvents) {
      setShowEconomicEvents(true);
    }
  };

  return (
    <div className="bg-gray-100 py-2 px-4 text-xs flex items-center justify-between border-t border-gray-200">
       <div className="flex items-center space-x-4">
        <span className="font-medium text-sm z-0 relative">Economic Events:</span>
        <div className="flex items-center space-x-2">
          {[-1, 0, 1].map((value) => (
            <button
              key={value}
              onClick={() => handleTimeframeChange(value)}
              className={`px-2 py-1 rounded text-xs ${
                selectedTimeframes.includes(value) ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 border border-gray-300'
              }`}
            >
              {value === -1 ? 'Last Week' : value === 0 ? 'This Week' : 'Next Week'}
            </button>
          ))}
        </div>
      </div>
      <div className="flex items-center">
        <span className="mr-2 font-medium text-sm">Geography:</span>
        <select
          value={geography}
          onChange={(e) => setGeography(e.target.value)}
          className="p-1 border rounded bg-white text-xs"
        >
          {['US', 'EU', 'UK', 'JP', 'CN'].map((country) => (
            <option key={country} value={country}>{country}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default EconomicEventControls;