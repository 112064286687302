import { DEFAULT_COLOR_SETTINGS } from '../config/constants';
import { DEFAULT_COLUMNS } from '../config/columnConfig';
import { MARKET_CAP_TIERS } from '../components/MarketCapFilter';

export const loadSettings = () => {
  const settings = localStorage.getItem('userSettings');
  return settings ? JSON.parse(settings) : {
    colorSettings: DEFAULT_COLOR_SETTINGS,
    columns: DEFAULT_COLUMNS.map(col => col.key),
    marketCapFilters: MARKET_CAP_TIERS.map(tier => tier.id)
  };
};

export const saveSettings = (category, value) => {
  const currentSettings = loadSettings();
  const updatedSettings = {
    ...currentSettings,
    [category]: value
  };
  localStorage.setItem('userSettings', JSON.stringify(updatedSettings));
  return updatedSettings;
};
