import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronDown, FiChevronUp, FiTrendingUp, FiClock, FiAlertTriangle } from 'react-icons/fi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import CONFIG from '../config/config';

const fetchMarketAnalysis = async (forceRefresh = false) => {
  const today = new Date().toISOString().split('T')[0];
  try {
    const response = await axios.get(
      `https://market_news_2.drisw.workers.dev/?date=${today}&category=239&forceRefresh=${forceRefresh}`
    );
    console.log('Raw API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching market analysis:', error);
    throw error;
  }
};

const MarketAnalysisRow = () => {
  const [marketPulseData, setMarketPulseData] = useState(null);
  const [strategiesExpanded, setStrategiesExpanded] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [categoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isMainSectionExpanded, setIsMainSectionExpanded] = useState(false);

  const { data: analysisData, isLoading, error } = useQuery({
    queryKey: ['marketAnalysis'],
    queryFn: () => fetchMarketAnalysis(),
    refetchInterval: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000,
    cacheTime: 2 * 60 * 60 * 1000,
  });

  // Fetch market pulse data
  useEffect(() => {
    fetch(CONFIG.API_URLS.DAILY_MOVEMENT_ANALYTICS)
      .then(response => response.json())
      .then(data => setMarketPulseData(data))
      .catch(error => console.error('Error fetching market pulse:', error));
  }, []);

  // Debug logging
  useEffect(() => {
    if (analysisData) {
      console.log('Market Analysis Data:', analysisData);
      console.log('Analysis Structure:', {
        hasAnalysis: !!analysisData?.analysis,
        hasSummary: !!analysisData?.analysis?.summary,
        hasStrategies: !!analysisData?.analysis?.summary?.InvestmentStrategies,
        strategiesCount: analysisData?.analysis?.summary?.InvestmentStrategies?.length
      });
    }
  }, [analysisData]);

  // Add this near your other useEffect hooks
  useEffect(() => {
    if (analysisData) {
      console.log('Categorized Analysis:', analysisData?.analysis?.categorizedAnalysis);
      console.log('Categories Expanded:', categoriesExpanded);
      console.log('Expanded Category:', expandedCategory);
    }
  }, [analysisData, categoriesExpanded, expandedCategory]);

  const getSentimentBadge = (sentiment) => {
    const colors = {
      positive: 'bg-green-100 text-green-800 font-medium',
      negative: 'bg-red-100 text-red-800 font-medium',
      neutral: 'bg-yellow-50 text-yellow-800 font-medium',
    };
    return `text-xs px-2 py-0.5 rounded-full ${colors[sentiment.toLowerCase()] || colors.neutral}`;
  };

  const getMarketPulseAnalysis = (data) => {
    if (!data) return '';
    
    const total = data.numPriceIncreases + data.numPriceDecreases;
    const upPercent = Math.round((data.numPriceIncreases / total) * 100);
    const downPercent = Math.round((data.numPriceDecreases / total) * 100);
    
    return (
      <div className="space-y-2">
        <div className="flex items-center gap-3">
          <div className="market-pulse-advancing">
            {upPercent}% advancing 
          </div>
          <div className="market-pulse-declining">
            {downPercent}% declining 
          </div>
        </div>
        
        <div className="space-y-1.5">
          <div className="flex gap-2">
            <span className="text-green-600 font-medium min-w-[4.5rem]">Leading:</span>
            <span className="text-gray-600">
              {data.topIndustriesIncreases
                ?.slice(0, 3)
                .map(item => item.industry)
                .join(', ')}
            </span>
          </div>
          <div className="flex gap-2">
            <span className="text-red-600 font-medium min-w-[4.5rem]">Lagging:</span>
            <span className="text-gray-600">
              {data.topIndustriesDecreases
                ?.slice(0, 3)
                .map(item => item.industry)
                .join(', ')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const getRiskLevelBadge = (riskLevel) => {
    const colors = {
      low: 'bg-green-100 text-green-800',
      medium: 'bg-yellow-100 text-yellow-800',
      high: 'bg-red-100 text-red-800',
    };
    return colors[riskLevel] || colors.medium;
  };

  const getTimeHorizonIcon = (horizon) => {
    switch (horizon) {
      case 'short-term':
        return <span className="text-blue-600">ST</span>;
      case 'medium-term':
        return <span className="text-purple-600">MT</span>;
      case 'long-term':
        return <span className="text-indigo-600">LT</span>;
      default:
        return null;
    }
  };

  const getSentimentClass = (sentiment) => {
    return `sentiment-badge sentiment-${sentiment.toLowerCase()}`;
  };

  if (isLoading) return <div>Loading market analysis...</div>;
  if (error) return <div>Error loading market analysis: {error.message}</div>;
  if (!analysisData) return null;

  return (
    <div className="market-analysis-container">
      <div className="bg-white shadow rounded-lg">
        <div className="flex items-center justify-between px-4 py-3 cursor-pointer border-b border-gray-200" 
             onClick={() => setIsMainSectionExpanded(!isMainSectionExpanded)}>
          <div className="flex items-center gap-4">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">Market Analysis</h2>
            </div>
            <span className="px-2.5 py-0.5 text-sm font-medium bg-gray-100 text-gray-600 rounded-full">
              neutral
            </span>
            
            {!isMainSectionExpanded && marketPulseData && (
              <div className="flex items-center gap-3">
                <div className="market-pulse-advancing">
                  {Math.round((marketPulseData.numPriceIncreases / (marketPulseData.numPriceIncreases + marketPulseData.numPriceDecreases)) * 100)}% advancing
                </div>
                <div className="market-pulse-declining">
                  {Math.round((marketPulseData.numPriceDecreases / (marketPulseData.numPriceIncreases + marketPulseData.numPriceDecreases)) * 100)}% declining
                </div>
              </div>
            )}
          </div>
          
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <FontAwesomeIcon
                icon={faMagicWandSparkles}
                className="w-4 h-4 text-blue-600 mr-1"
              />
              AI Assisted
            </div>
            {isMainSectionExpanded ? (
              <FiChevronUp className="w-4 h-4 text-gray-600" />
            ) : (
              <FiChevronDown className="w-4 h-4 text-gray-600" />
            )}
          </div>
        </div>

        {isMainSectionExpanded && (
          <div className="border-t border-gray-200">
            {/* Summary */}
            <div className="px-4 py-3">
              <table className="w-full text-sm">
                <tbody>
                  <tr className="align-top bg-gray-50/50">
                    <td className="py-3 pr-6 w-40">
                      <span className="font-semibold text-blue-900">Market Pulse:</span>
                    </td>
                    <td className="py-3">
                      {marketPulseData ? getMarketPulseAnalysis(marketPulseData) : 'Loading market pulse...'}
                    </td>
                  </tr>
                  
                  <tr className="align-top">
                    <td className="py-2.5 pr-6 w-40">
                      <span className="font-semibold text-blue-900">Market Outlook:</span>
                    </td>
                    <td className="py-2.5 text-gray-600">
                      {analysisData?.analysis?.summary?.marketOutlook}
                    </td>
                  </tr>
                  
                  <tr className="align-top bg-gray-50/50">
                    <td className="py-2.5 pr-6 w-40">
                      <span className="font-semibold text-blue-900">Risks:</span>
                    </td>
                    <td className="py-2.5 text-gray-600">
                      {analysisData?.analysis?.summary?.keyRisks?.join(' • ')}
                    </td>
                  </tr>
                  
                  <tr className="align-top">
                    <td className="py-2.5 pr-6 w-40">
                      <span className="font-semibold text-blue-900">Opportunities:</span>
                    </td>
                    <td className="py-2.5 text-gray-600">
                      {analysisData?.analysis?.summary?.opportunities?.join(' • ')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Investment Strategies Section */}
            <button
              onClick={() => setStrategiesExpanded(!strategiesExpanded)}
              className="w-full flex items-center justify-between py-2 px-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
            >
              <span className="text-sm font-medium text-gray-800">
                {strategiesExpanded ? 'Hide Investment Strategies' : 'Show Investment Strategies'}
              </span>
              {strategiesExpanded ? (
                <FiChevronUp className="w-4 h-4 text-gray-800" />
              ) : (
                <FiChevronDown className="w-4 h-4 text-gray-800" />
              )}
            </button>

            {strategiesExpanded && (
              <div className="px-4 py-3">
                <div className="grid gap-4 md:grid-cols-2">
                  {analysisData?.analysis?.summary?.InvestmentStrategies?.map((strategy, index) => (
                    <div key={index} className="border rounded-lg p-4 bg-gray-50">
                      <div className="flex flex-wrap items-center gap-4 md:gap-6">
                        {/* Strategy Description */}
                        <div className="flex items-center gap-2 min-w-[250px] flex-1">
                          <FiTrendingUp className="w-4 h-4 text-gray-500" />
                          <span className="text-gray-900">{strategy.strategy}</span>
                        </div>

                        {/* Risk Level */}
                        <div className="flex items-center">
                          <span className={`
                            px-2.5 py-1 text-sm rounded-md
                            ${getRiskLevelBadge(strategy.riskLevel.toLowerCase())}
                          `}>
                            {strategy.riskLevel}
                          </span>
                        </div>

                        {/* Return Expectation */}
                        <div className="flex items-center gap-2 min-w-[100px]">
                          <FiTrendingUp className="w-4 h-4 text-gray-400" />
                          <div>
                            <div className="text-xs text-gray-500">Return</div>
                            <div className="font-medium">{strategy.expectedReturn}</div>
                          </div>
                        </div>

                        {/* Time Horizon */}
                        <div className="flex items-center gap-2 min-w-[100px]">
                          <FiClock className="w-4 h-4 text-gray-400" />
                          <div>
                            <div className="text-xs text-gray-500">Horizon</div>
                            <div className="font-medium">
                              {strategy.timeHorizon.replace('-', ' ')}
                            </div>
                          </div>
                        </div>

                        {/* Asset Classes */}
                        <div className="flex flex-wrap gap-2">
                          {strategy.assetClasses.map((assetClass, idx) => (
                            <span 
                              key={idx}
                              className="px-2.5 py-1 text-sm bg-gray-100 text-gray-700 rounded-md"
                            >
                              {assetClass}
                            </span>
                          ))}
                        </div>
                      </div>

                      {/* Risk-Return Profile */}
                      {strategy.expectedRiskReturnProfile && (
                        <div className="mt-3 flex items-start gap-2">
                          <FiAlertTriangle className="w-4 h-4 text-gray-400 mt-0.5" />
                          <p className="text-sm text-gray-600">
                            {strategy.expectedRiskReturnProfile}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Toggle Button for Categories Section */}
            <button
              onClick={() => setCategoriesExpanded(!categoriesExpanded)}
              className="w-full flex items-center justify-between py-2 px-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
            >
              <span className="text-sm font-medium text-gray-800">
                {categoriesExpanded ? 'Hide Detailed Analysis' : 'Show Detailed Analysis'}
              </span>
              {categoriesExpanded ? (
                <FiChevronUp className="w-4 h-4 text-gray-800" />
              ) : (
                <FiChevronDown className="w-4 h-4 text-gray-800" />
              )}
            </button>

            {/* Categorized Analysis */}
            {categoriesExpanded && (
              <div className="px-4 py-3">
                <div className="space-y-2">
                  {Object.entries(analysisData?.analysis?.categorizedAnalysis || {}).map(
                    ([category, data]) => (
                      <div key={category} className="border rounded-md overflow-hidden">
                        <button
                          onClick={() =>
                            setExpandedCategory(
                              expandedCategory === category ? null : category
                            )
                          }
                          className="w-full flex items-center justify-between py-2 px-3 bg-gray-50 hover:bg-gray-100 focus:outline-none"
                        >
                          <span className="text-sm font-medium text-gray-800">
                            {category.replace(/_/g, ' ')}
                          </span>
                          <div className="flex items-center gap-2">
                            <span
                              className={getSentimentClass(data.sentiment)}
                            >
                              {data.sentiment}
                            </span>
                            {expandedCategory === category ? (
                              <FiChevronDown className="w-4 h-4 text-gray-800" />
                            ) : (
                              <FiChevronRight className="w-4 h-4 text-gray-800" />
                            )}
                          </div>
                        </button>
                        {expandedCategory === category && (
                          <div className="px-3 py-2 text-sm text-gray-700">
                            <p className="mb-2">{data.analysis}</p>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketAnalysisRow;
