import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { debounce } from 'lodash';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useWatchlistData, fetchScreenData, useEconomicData, useMarketAnalysis, updateWatchlist, updateUserData } from '../services/api';
import StockTable from './StockTable';
import CONFIG from '../config/config';
import axios from 'axios';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import { jwtDecode } from 'jwt-decode';
import EarningsWidget from './EarningsWidget';
//import DailyMovementsWidget from './DailyMovementsWidget';
import AddSymbolButton from './AddSymbolButton';
import Modal from 'react-modal';
import ProfileModal from './ProfileModal';
import { DEFAULT_COLOR_SETTINGS } from '../config/constants';
import MarketAnalysisRow from './MarketAnalysisRow2';
import { DEFAULT_COLUMNS, ALL_COLUMNS } from '../config/columnConfig'; // Added import
import { loadSettings, saveSettings } from '../utils/settings';
import FilterDropdowns from './FilterDropdown';
import SettingsDialog from './SettingsDialog';
import { MARKET_CAP_TIERS } from './MarketCapFilter';
import ReactDOM from 'react-dom';

library.add(fas);

const DEBUG = {
  columns: false,    // Column-related logs
  data: true,       // Data loading logs
  filters: true,    // Filter-related logs
  sync: false,      // User data sync logs
  settings: false,  // Settings-related logs
  market: false     // Market analysis logs
};

// Define the semi-permanent status message
const APP_STATUS = {
  message: "This app is still in active development mode. We're launching very soon!",
  type: "info"
};

const getIconName = (iconString) => {
  return iconString.startsWith('fa-') ? iconString.slice(3) : iconString;
};

const calculateTrialDaysLeft = (timestamp) => {
  const today = new Date();
  const firstUseDate = new Date(timestamp);
  const diffTime = Math.abs(today - firstUseDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.max(0, 21 - diffDays);
};

const App = () => {
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesSymbols, setCompaniesSymbols] = useState(() => {
    const savedSymbols = localStorage.getItem('companiesSymbols');
    return savedSymbols ? JSON.parse(savedSymbols) : [];
  });
  const [selectedTab, setSelectedTab] = useState('watchlist');
  const [news, setNews] = useState([]);
  const [analysis, setAnalysis] = useState('');
  const [guidance, setGuidance] = useState('');
  const [relatedEquities, setRelatedEquities] = useState([]);
  const [error, setError] = useState('');
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [firstUseTimestamp, setFirstUseTimestamp] = useState(() => {
    const savedTimestamp = localStorage.getItem('firstUseTimestamp');
    return savedTimestamp || new Date().toISOString();
  });
  const [trialDaysLeft, setTrialDaysLeft] = useState(() => calculateTrialDaysLeft(firstUseTimestamp));
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [selectedTimeframes, setSelectedTimeframes] = useState([0]);
  const [geography, setGeography] = useState('US');
  const [showEconomicEvents, setShowEconomicEvents] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('info');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: null });
  const [userSettings, setUserSettings] = useState(() => {
    const settings = loadSettings();
    console.log('Initial settings loaded:', settings);
    return settings;
  });
  const [colorSettings, setColorSettings] = useState(() => userSettings.colorSettings);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isColorSettingsOpen, setIsColorSettingsOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState(() => {
    const settings = loadSettings();
    return {
      marketCap: settings.marketCapFilters || MARKET_CAP_TIERS.map(tier => tier.id)
    };
  });

  const isSyncing = useRef(false);
  const lastSyncedData = useRef({});

  const { data: marketAnalysisData, isLoading: isLoadingMarketAnalysis } = useMarketAnalysis();
  const { data: watchlistData, refetch: refetchWatchlistData } = useWatchlistData(loggedInUser);

  const updateUserWatchlist = useCallback(async (symbols) => {
    if (!loggedInUser) return;
    try {
      await updateWatchlist(loggedInUser, symbols);
      await refetchWatchlistData();
    } catch (error) {
      console.error('Error updating user watchlist:', error);
      setError('Failed to update watchlist. Please try again.');
    }
  }, [loggedInUser, refetchWatchlistData]);

  const syncUserData = useCallback(async (email) => {
    if (!email || isSyncing.current) return;
    isSyncing.current = true;
    console.log('Syncing user data for', email);
  
    try {
      const result = await refetchWatchlistData();
      console.log('Fetched user data:', result);
  
      let serverSymbols = [];
      let serverSettings = {};
      if (result && result.data) {
        serverSymbols = result.data.companies_symbols || [];
        serverSettings = result.data.preferences || {};
      }
  
      const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
      const mergedSymbols = Array.from(new Set([...localSymbols, ...serverSymbols]));
      
      setCompaniesSymbols(mergedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(mergedSymbols));
  
      // Merge local and server settings
      const localSettings = loadSettings();
      const mergedSettings = {
        ...localSettings,
        ...serverSettings,
      };
  
      setUserSettings(mergedSettings);
      localStorage.setItem('userSettings', JSON.stringify(mergedSettings));
  
      // Update specific states based on settings
      if (mergedSettings.colorSettings) setColorSettings(mergedSettings.colorSettings);
      if (mergedSettings.columns) setColumns(mergedSettings.columns);
      if (mergedSettings.marketCapFilters) {
        setActiveFilters(prev => ({
          ...prev,
          marketCap: mergedSettings.marketCapFilters
        }));
      }
  
      if (mergedSymbols.length > serverSymbols.length) {
        await updateUserWatchlist(mergedSymbols);
      }
  
      console.log('User data synced successfully');
    } catch (error) {
      console.error('Error syncing user data:', error);
      const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
      setCompaniesSymbols(localSymbols);
      const localSettings = loadSettings();
      setUserSettings(localSettings);
    } finally {
      isSyncing.current = false;
    }
  }, [refetchWatchlistData, updateUserWatchlist]);

  const handleSettingsSave = async (settingsData, category) => {
    setUserSettings((prevUserSettings) => {
      const updatedSettings = { ...prevUserSettings, [category]: settingsData };
      localStorage.setItem('userSettings', JSON.stringify(updatedSettings));

      if (loggedInUser) {
        updateUserData(loggedInUser, { preferences: updatedSettings }).catch((error) => {
          console.error('Error saving user settings:', error);
        });
      }

      return updatedSettings;
    });
  };

  useEffect(() => {
    if (marketAnalysisData) {
      if (DEBUG.market) {
        console.log('Market Analysis Data:', marketAnalysisData);
      }
      setNews(marketAnalysisData.articles);
      setAnalysis(marketAnalysisData.analysis);
      setGuidance(marketAnalysisData.guidance);
      setRelatedEquities(marketAnalysisData.relatedEquities);
    }
  }, [marketAnalysisData]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes
      },
    },
  });

  queryClient.setQueryDefaults(['marketAnalysis'], {
    staleTime: 60 * 60 * 1000, // 1 hour
    cacheTime: 2 * 60 * 60 * 1000, // 2 hours
  });

  

  const { 
    data: allEconomicEvents, 
    isLoading: isLoadingEconomicEvents, 
    error: economicError 
  } = useEconomicData(geography);



  const fetchNews = async () => {
    if (DEBUG.data) {
      console.log('Fetching news');
    }
    const today = new Date().toISOString().split('T')[0];
    try {
      const response = await axios.get(`https://market_news.drisw.workers.dev/?date=${today}&category=239`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch news:', error);
      throw error;
    }
  };

  // Define filterCompaniesByMarketCap first
  const filterCompaniesByMarketCap = useCallback((companies, selectedTiers) => {
    if (!Array.isArray(companies)) return [];
    if (!selectedTiers || selectedTiers.length === 0) return companies;
    
    return companies.filter(company => {
      const marketCap = company.marketCap;
      
      if (marketCap === undefined || marketCap === null) return false;

      // Find the appropriate tier directly
      let matchingTier = null;
      for (const tier of MARKET_CAP_TIERS) {
        if ((!tier.min || marketCap >= tier.min) && (!tier.max || marketCap < tier.max)) {
          matchingTier = tier;
          break; // Stop once we find the matching tier
        }
      }

      if (DEBUG.filters) {
        console.log(`${company.tickerSymbol} ($${(marketCap/1e9).toFixed(2)}B) belongs to tier ${matchingTier?.id}`);
      }

      return matchingTier && selectedTiers.includes(matchingTier.id);
    });
  }, []);

  // Then define loadData
  const loadData = useCallback(async (forceRefresh = false) => {
    try {
      setIsLoading(true);
      let rawData;
      
      if (selectedTab === 'watchlist') {
        if (DEBUG.data) console.log('Loading watchlist data for:', loggedInUser || 'local storage');
        
        if (loggedInUser) {
          // For logged in users, use the existing watchlistData hook
          const result = await refetchWatchlistData();
          rawData = result?.data || [];
        } else {
          // For non-logged in users, get symbols from local storage
          const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
          if (localSymbols.length > 0) {
            rawData = await fetchScreenData('watchlist', null, localSymbols);
          } else {
            rawData = [];
          }
        }
      } else {
        rawData = await fetchScreenData(selectedTab);
      }

      if (DEBUG.data) {
        console.log('Raw data loaded:', {
          tab: selectedTab,
          count: rawData?.length,
          firstItem: rawData?.[0]
        });
      }

      const filteredData = filterCompaniesByMarketCap(rawData, activeFilters.marketCap);
      setCompaniesData(filteredData);
    } catch (error) {
      console.error('Error loading data:', error);
      setError('Failed to load data');
    } finally {
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  }, [selectedTab, activeFilters.marketCap, filterCompaniesByMarketCap, loggedInUser, refetchWatchlistData]);

  // Add an effect to watch filter changes
  useEffect(() => {
    if (activeFilters) {
      console.log('Filter effect triggered:', activeFilters);
      loadData(true);
    }
  }, [activeFilters, loadData]);

  const handleFilterApply = useCallback((filters) => {
    console.log('Filter apply start with:', filters);

    // Update state first
    setActiveFilters(filters);
    
    // Save settings
    const updatedSettings = saveSettings('marketCapFilters', filters.marketCap);
    
    if (loggedInUser) {
      updateUserData(loggedInUser, {
        preferences: {
          ...userSettings,
          marketCapFilters: filters.marketCap
        }
      }).catch((error) => {
        console.error('Error saving filters:', error);
      });
    }
  }, [loggedInUser, userSettings]);

  // Data loading effect
  useEffect(() => {
    loadData();
  }, [loadData]);

  const debouncedSync = useCallback(
    debounce((email, data) => {
      if (JSON.stringify(data) !== JSON.stringify(lastSyncedData.current)) {
        syncUserData(email, data);
        lastSyncedData.current = data;
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (loggedInUser && companiesSymbols.length > 0) {
      debouncedSync(loggedInUser, companiesSymbols);
    }
  }, [loggedInUser, companiesSymbols, debouncedSync]);

  const handleAddToWatchList = useCallback(async (companyOrSymbol) => {
    const tickerSymbol = typeof companyOrSymbol === 'string' 
      ? companyOrSymbol.trim().toUpperCase()
      : companyOrSymbol.tickerSymbol;

    if (!tickerSymbol) {
      setError('Invalid symbol. Please enter a valid stock symbol.');
      setStatusType('error');
      setTimeout(() => setError(''), 5000);
      return;
    }

    if (companiesSymbols.includes(tickerSymbol)) {
      setError(`${tickerSymbol} is already in your watch list.`);
      setStatusType('error');
      setTimeout(() => setError(''), 5000);
      return;
    }

    setIsLoading(true);
    try {
      const updatedSymbols = [...companiesSymbols, tickerSymbol];
      
      if (loggedInUser) {
        await updateWatchlist(loggedInUser, updatedSymbols);
      }

      setCompaniesSymbols(updatedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(updatedSymbols));

      // Fetch the data for the new symbol
      const newSymbolData = await fetchScreenData(null, null, [tickerSymbol]);
      if (newSymbolData && newSymbolData.length > 0) {
        setCompaniesData(prevData => [...prevData, newSymbolData[0]]);
      }

      setStatusMessage(`${tickerSymbol} added to watch list successfully.`);
      setStatusType('success');
      
      await queryClient.invalidateQueries(['companies', 'watchlist']);
    } catch (error) {
      if (DEBUG.data) {
        console.error('Error adding new symbol:', error);
      }
      setError(`Failed to add ${tickerSymbol} to watch list: ${error.message}`);
      setStatusType('error');
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setError('');
        setStatusMessage('');
      }, 5000);
    }
  }, [companiesSymbols, loggedInUser, updateWatchlist, queryClient, fetchScreenData]);

  const handleDeleteFromWatchList = useCallback(async (tickerSymbol) => {
    try {
      const updatedSymbols = companiesSymbols.filter(symbol => symbol !== tickerSymbol);
      setCompaniesSymbols(updatedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(updatedSymbols));
  
      setCompaniesData(prevData => prevData.filter(company => company.tickerSymbol !== tickerSymbol));
  
      if (loggedInUser) {
        await updateUserWatchlist(updatedSymbols);
      }
    } catch (error) {
      if (DEBUG.data) {
        console.error(`Error deleting ${tickerSymbol} from watchlist:`, error);
      }
      setError(`Failed to delete ${tickerSymbol} from watchlist.`);
      setTimeout(() => setError(''), 5000);
    }
  }, [companiesSymbols, loggedInUser, updateUserWatchlist]);

  const handleLoginSuccess = useCallback((email) => {
    setLoggedInUser(email); // Make sure this is the email string
    syncUserData(email);
    setStatusMessage(`Welcome back, ${email}!`);
    setStatusType('success');
    setTimeout(() => setStatusMessage(''), 5000);
  }, [syncUserData]);
  
  useEffect(() => {
    const initializeUserData = async () => {
      if (loggedInUser && !isSyncing.current) {
        setIsLoading(true);
        isSyncing.current = true;
        try {
          await syncUserData(loggedInUser);
          await queryClient.invalidateQueries(['userData', loggedInUser]);
          await queryClient.invalidateQueries(['economicData']);
          await queryClient.invalidateQueries(['companies']);
          await loadData();
        } catch (error) {
          if (DEBUG.sync) {
            console.error('Error initializing user data:', error);
          }
          setError('Failed to initialize user data. Please try again.');
        } finally {
          setIsLoading(false);
          isSyncing.current = false;
        }
      }
    };
  
    initializeUserData();
  }, [loggedInUser]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken && decodedToken.email) {
        setLoggedInUser(decodedToken.email);
      } else {
        setLoggedInUser(null);
      }
    } else {
      setLoggedInUser(null);
    }
  }, []);

  const filteredEconomicEvents = useMemo(() => {
    if (!allEconomicEvents || !showEconomicEvents) return [];
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
  
    return Object.entries(allEconomicEvents).flatMap(([date, events]) => {
      const eventDate = new Date(date);
      const weekDiff = Math.floor((eventDate - startOfWeek) / (7 * 24 * 60 * 60 * 1000));
      
      if (selectedTimeframes.includes(weekDiff)) {
        return events.map(event => ({
          ...event,
          date,
          timeframe: weekDiff
        }));
      }
      return [];
    });
  }, [allEconomicEvents, selectedTimeframes, showEconomicEvents]);

  // Initialize columns state
  const [columns, setColumns] = useState(() => {
    const savedSettings = loadSettings();
    const savedColumnKeys = savedSettings.columns || [];
    
    // Get fixed columns first
    const fixedColumns = ALL_COLUMNS.filter(col => col.isFixed);
    const fixedKeys = fixedColumns.map(col => col.key);
    
    // Combine fixed and saved columns, maintaining order
    const allColumnKeys = [...fixedKeys, ...savedColumnKeys.filter(key => !fixedKeys.includes(key))];
    
    return allColumnKeys
      .map(key => ALL_COLUMNS.find(col => col.key === key))
      .filter(Boolean);
  });

  // Handle saving column configuration
  const handleSaveColumnConfig = useCallback(
    (newColumnKeys) => {
      // Reconstruct the columns array directly from newColumnKeys
      const newColumns = newColumnKeys
        .map((key) => ALL_COLUMNS.find((col) => col.key === key))
        .filter(Boolean);

      setColumns(newColumns);

      // Save all selected column keys to local storage
      localStorage.setItem(
        'columnsConfig',
        JSON.stringify(newColumnKeys)
      );

      handleSettingsSave(newColumnKeys, 'columns');
    },
    [handleSettingsSave]
  );

  const handleColumnReorder = useCallback((newColumns) => {
    setColumns(newColumns);

    const newColumnKeys = newColumns.map((col) => col.key);

    // Save new order to localStorage
    localStorage.setItem('columnsConfig', JSON.stringify(newColumnKeys));

    // Update user settings
    handleSettingsSave(newColumnKeys, 'columns');
  }, [handleSettingsSave]);

  // Define handlers before using them in useMemo
  const handleColorSettingsClick = useCallback(() => {
    setIsColorSettingsOpen(true);
  }, []);

  const handleColorSettingsSave = useCallback((newSettings) => {
    setColorSettings(newSettings);
    const updatedSettings = saveSettings('colorSettings', newSettings);
    setUserSettings(updatedSettings);
    
    if (loggedInUser) {
      updateUserData(loggedInUser, { 
        preferences: updatedSettings 
      }).catch((error) => {
        if (DEBUG.settings) {
          console.error('Error saving color settings:', error);
        }
      });
    }
  }, [loggedInUser]);

  // Stock table memo (after all dependencies are defined)
  const memoizedStockTable = useMemo(
    () => (
      <StockTable
        companyData={companiesData}
        economicEvents={filteredEconomicEvents}
        onAddToWatchList={handleAddToWatchList}
        onDelete={handleDeleteFromWatchList}
        news={news}
        analysis={analysis}
        guidance={guidance}
        relatedEquities={relatedEquities}
        loadingTable={isLoading}
        selectedTimeframes={selectedTimeframes}
        setSelectedTimeframes={setSelectedTimeframes}
        geography={geography}
        setGeography={setGeography}
        showEconomicEvents={showEconomicEvents}
        setShowEconomicEvents={setShowEconomicEvents}
        selectedTab={selectedTab}
        colorSettings={colorSettings}
        columns={columns}
        onColumnReorder={handleColumnReorder}
        onColorSettingsClick={handleColorSettingsClick}
      />
    ),
    [companiesData, filteredEconomicEvents, handleAddToWatchList, handleDeleteFromWatchList, 
     news, analysis, guidance, relatedEquities, isLoading, selectedTimeframes, 
     setSelectedTimeframes, geography, setGeography, showEconomicEvents, 
     setShowEconomicEvents, selectedTab, colorSettings, columns, handleColumnReorder, 
     handleColorSettingsClick]
  );

  if (DEBUG.settings) {
    console.log('APP_STATUS in App.js:', APP_STATUS);
  }

  const handleDeleteProfile = useCallback(async () => {
    if (window.confirm("Are you sure you want to delete your profile? This action cannot be undone.")) {
      try {
        // Implement the delete profile logic here
        // For example:
        // await deleteUserProfile(loggedInUser);
        setLoggedInUser(null);
        localStorage.removeItem('authToken');
        setModalIsOpen(false);
        setStatusMessage('Your profile has been deleted successfully.');
        setStatusType('success');
      } catch (error) {
        console.error('Error deleting profile:', error);
        setStatusMessage('Failed to delete profile. Please try again.');
        setStatusType('error');
      }
    }
  }, [loggedInUser, setStatusMessage, setStatusType]);

  const handleProfileClick = useCallback(() => {
    setModalContent({
      title: 'Profile',
      content: <ProfileModal loggedInUser={loggedInUser} onDeleteProfile={handleDeleteProfile} onClose={() => setModalIsOpen(false)} />
    });
    setModalIsOpen(true);
  }, [loggedInUser, handleDeleteProfile]);

  const handleSubscriptionClick = useCallback(() => {
    setModalContent({
      title: 'Subscription',
      content: <p>Subscription management options will go here.</p>
    });
    setModalIsOpen(true);
  }, []);

  useEffect(() => {
    console.log('Modal open state changed:', modalIsOpen);
  }, [modalIsOpen]);

  const handleAddSymbolClick = useCallback(() => {
    setModalContent({
      title: 'Add Symbol to Watch List',
      content: (
        <AddSymbolButton 
          onAddSymbol={handleAddToWatchList}
          existingSymbols={companiesSymbols}
          isLoggedIn={!!loggedInUser}
          className="w-full"
        >
          Search and add symbols to your watch list
        </AddSymbolButton>
      )
    });
    setModalIsOpen(true);
  }, [handleAddToWatchList, companiesSymbols, loggedInUser]);


  useEffect(() => {
    if (userSettings.colorSettings) {
      setColorSettings(userSettings.colorSettings);
    }
    
    if (userSettings.columns) {
      const savedColumnKeys = userSettings.columns;
      const fixedColumns = ALL_COLUMNS.filter(col => col.isFixed);
      const fixedKeys = fixedColumns.map(col => col.key);
      
      // Combine fixed and saved columns, maintaining order
      const allColumnKeys = [...fixedKeys, ...savedColumnKeys.filter(key => !fixedKeys.includes(key))];
      
      const allColumns = allColumnKeys
        .map(key => ALL_COLUMNS.find(col => col.key === key))
        .filter(Boolean);
      
      if (allColumns.length > 0) {
        setColumns(allColumns);
      }
    }
  }, [userSettings]);

  if (DEBUG.columns) {
    console.log('columns in App.js before rendering StockTable:', columns);
  }
  // Load user preferences
  useEffect(() => {
    const loadUserPreferences = async () => {
      try {
        const savedSettings = await loadSettings();
        setUserSettings(savedSettings);

        if (savedSettings.columns) {
          const savedColumnKeys = savedSettings.columns;
          const fixedColumns = ALL_COLUMNS.filter((col) => col.isFixed).map(
            (col) => col.key
          );

          const allColumnKeys = Array.from(
            new Set([...fixedColumns, ...savedColumnKeys])
          );

          const allColumns = allColumnKeys
            .map((key) => ALL_COLUMNS.find((col) => col.key === key))
            .filter(Boolean);

          if (allColumns.length > 0) {
            setColumns(allColumns);
          }
        }
        // ... handle other settings ...
      } catch (error) {
        console.error("Error loading user preferences:", error);
      }
    };

    loadUserPreferences();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && !event.target.closest('.inline-flex')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeDropdown]);

  const handleDropdownToggle = (categoryId) => {
    setActiveDropdown(current => current === categoryId ? null : categoryId);
  };

  const handleCategorySelect = (categoryId) => {
    if (categoryId === 'personal') {
      setSelectedTab('watchlist'); // Default to watchlist when My Lists is selected
    } else {
      setSelectedTab(categoryId);
    }
  };

  // Replace the filter persistence effect with an effect that handles both initial load and updates
  useEffect(() => {
    const savedSettings = loadSettings();
    if (savedSettings.marketCapFilters && 
        JSON.stringify(savedSettings.marketCapFilters) !== JSON.stringify(activeFilters.marketCap)) {
      console.log('Updating filters from saved settings:', savedSettings.marketCapFilters);
      setActiveFilters(prev => ({
        ...prev,
        marketCap: savedSettings.marketCapFilters
      }));
    }
  }, [activeFilters.marketCap]); // Only run when marketCap filters change

  return (
    <QueryClientProvider client={queryClient}>
    <div className="container mx-auto mt-4 px-4">
      <Header 
        onLoginSuccess={handleLoginSuccess}
        onLogout={() => {
          setLoggedInUser(null);
          setStatusMessage('You have been logged out.');
          setStatusType('info');
          setTimeout(() => setStatusMessage(''), 5000);
        }}
        trialDaysLeft={trialDaysLeft} 
        isSubscribed={isSubscribed} 
        statusMessage={statusMessage}
        statusType={statusType}
        appStatus={APP_STATUS}  // Always pass the APP_STATUS
        onProfileClick={handleProfileClick}
        onSubscriptionClick={handleSubscriptionClick}
        onSettingsSave={handleSettingsSave}
      />
     {/* <EarningsWidget apiUrl={CONFIG.API_URLS.EARNINGS_ANALYTICS} /> 
      <DailyMovementsWidget apiUrl={CONFIG.API_URLS.DAILY_MOVEMENT_ANALYTICS} />*/}
      <MarketAnalysisRow
        analysis={analysis}
        guidance={guidance}
        relatedEquities={relatedEquities}
      />
      {error && <div className="text-red-500 text-center p-4">{error}</div>}
      <FilterDropdowns 
        selectedTab={selectedTab}
        onSelectTab={handleCategorySelect}
        onAddSymbol={handleAddToWatchList}
        loggedInUser={loggedInUser}
        existingSymbols={companiesSymbols}
        activeFilters={activeFilters}
        onFilterApply={handleFilterApply}
      />
      {memoizedStockTable}
      <Footer />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={modalContent.title}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-auto">
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-semibold text-gray-900">{modalContent.title}</h2>
            <button
              onClick={() => setModalIsOpen(false)}
              className="text-gray-400 hover:text-gray-600 text-xl font-bold"
              aria-label="Close"
            >
              ×
            </button>
          </div>
          <div className="p-4">
            {modalContent.content}
          </div>
        </div>
      </Modal>
      <SettingsDialog
        isOpen={isColorSettingsOpen}
        onClose={() => setIsColorSettingsOpen(false)}
        onSave={handleColorSettingsSave}
        initialSettings={colorSettings}
      />
    </div>
    </QueryClientProvider>
  );
};

export default React.memo(App);
