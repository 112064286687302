import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faHeart,
  faFire,
  faChartLine,
  faAnalytics,
  faDatabase,
  faSignal,
  faProjectDiagram,
  faRobot,
  faBrain,
  faWandMagicSparkles,
  faComments,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

const Footer = () => {
  const openConsentPreferences = (e) => {
    e.preventDefault();
    if (window.displayPreferenceModal) {
      window.displayPreferenceModal();
    } else {
      console.error('Termly script not loaded properly');
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="footer-logo-section">
            <FontAwesomeIcon icon={faBolt} className="footer-logo-icon" />
            <h1 className="footer-logo-name">First Principles Analytics</h1>
          </div>
          <div className="footer-made-with">
            <p>
              Built with{" "}
              <span className="icon-container" title="Love">
                <FontAwesomeIcon icon={faHeart} />
                <span className="tooltip">love</span>
              </span>{" "}
              and{" "}
              <span className="icon-container" title="Passion">
                <FontAwesomeIcon icon={faFire} />
                <span className="tooltip">passion</span>
              </span>{" "}
              in PNW, driven by the need for real, helpful, and timely{" "}
              <span className="icon-container" title="Analytics">
                <FontAwesomeIcon icon={faProjectDiagram} style={{ color: '#007bff' }} />
                <span className="tooltip">analytics</span>
              </span>
              , not a mountain of disparate{" "}
              <span className="icon-container" title="Data">
                <FontAwesomeIcon icon={faDatabase} style={{ color: '#28a745' }} />
                <span className="tooltip">data</span>
              </span>{" "}
              or after-the-fact{" "}
              <span className="icon-container" title="Opinions">
                <FontAwesomeIcon icon={faComments} style={{ color: '#ffc107' }} />
                <span className="tooltip">opinions</span>
              </span>
              . Assisted by{" "}
              <span className="icon-container" title="Generative AI">
                <FontAwesomeIcon icon={faWandMagicSparkles} style={{ color: '#ff69b4' }} />
                <span className="tooltip">GenAI</span>
              </span>
              .
            </p>
          </div>
         
          <p className="footer-copyright">
            <br /> &copy; 2024 First Principles Analytics, LLC. All rights reserved.
            
          </p>

          <p>   
            _____________
          </p>

          <div className="footer-links">
            <a 
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=8ac60459-f389-4d0f-a4b5-76b5c78e54cb" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {" | "}
            <a 
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ee43322a-156d-44a3-a834-e8caf6b4f304" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            {" | "}
            <a 
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=62a50a19-b4b3-4842-8c3e-90c4e7b2bdbf" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Cookie Policy
            </a> 
            {" | "}
            <a 
              href="#" 
              onClick={openConsentPreferences}
              className="termly-display-preferences"
            >
              Consent Preferences
            </a>
            {" | "}
            <a 
              href="https://app.termly.io/notify/ee43322a-156d-44a3-a834-e8caf6b4f304" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Data Subject Access Request
            </a>
          </div>

          <div className="footer-discord-link">
            <a
              href="https://discord.gg/YyXZPZ5Qdy"
              target="_blank"
              rel="noopener noreferrer"
              title="Become part of First Principles Community of Intelligent Investors"
            >
              <span className="icon-container" title="Join our Discord">
                <FontAwesomeIcon icon={faUsers} style={{ color: '#7289da' }} />
                <span className="tooltip">Discord</span>
              </span>
              Join 1st Principles Discord Server for News, Comments, Questions, Community.
            </a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-disclaimer">
            <p>
              The information provided on this website is for informational
              purposes only and does not constitute investment advice, financial
              advice, trading advice, or any other sort of advice. The content
              on this website is not intended to be and should not be
              interpreted as a recommendation to buy, sell, or hold any security
              or investment, nor does it represent an offer to buy or sell any
              security.
            </p>
            <p>
              The information on this website is general in nature and does not
              take into account your personal financial situation, goals, or
              risk tolerance. Before making any investment decisions, you should
              seek advice from a qualified and registered securities
              professional and conduct your own research and due diligence.
            </p>
            <p>
              This website and its content are provided "as is" and without any
              warranty, express or implied. The site owner/operator does not
              guarantee the accuracy, completeness, or timeliness of the
              information, and will not be liable for any errors or omissions,
              or for any actions taken based on the information provided.
            </p>
            <p>
              Note: The use of colors (e.g., green and red) to highlight stocks
              meeting certain criteria is for illustrative purposes only and
              does not imply a recommendation to buy or sell any security.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;