import React, { useRef, useCallback, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import AddSymbolButton from './AddSymbolButton';
import { useCategories } from '../services/api';
import { usePopper } from 'react-popper';
import SettingsDialog from './SettingsDialog';
import { FIELD_NAMES, DEFAULT_COLOR_SETTINGS } from '../config/constants';
import FilterHeaderIcons from './FilterHeaderIcons';
import PropTypes from 'prop-types';

const useClickOutside = (ref, popperRef, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const isInsideContainer = ref.current && ref.current.contains(event.target);
      const isInsidePopper = popperRef.current && popperRef.current.contains(event.target);
      
      if (isInsideContainer || isInsidePopper) {
        return;
      }
      
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, popperRef, handler]);
};

const FilterDropdowns = ({ 
  selectedTab, 
  onSelectTab, 
  onAddSymbol, 
  loggedInUser,
  existingSymbols, 
  activeFilters,
  onFilterApply, 
  watchlist
}) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [activeSettingsCategory, setActiveSettingsCategory] = useState(null);
  const [colorSettings, setColorSettings] = useState(() => {
    const savedSettings = localStorage.getItem('colorSettings');
    return savedSettings ? JSON.parse(savedSettings) : DEFAULT_COLOR_SETTINGS;
  });
  const { data: categories, isLoading, error } = useCategories();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleSettingsClick = (categoryId) => {
    setActiveSettingsCategory(categoryId);
    setIsSettingsOpen(true);
  };

  const handleSettingsSave = (newSettings) => {
    const validSettings = newSettings.filter(setting => 
      setting.conditions.every(condition => FIELD_NAMES.includes(condition.field))
    );
    setColorSettings(validSettings);
    localStorage.setItem('colorSettings', JSON.stringify(validSettings));
    setIsSettingsOpen(false);
  };

  const handleDropdownToggle = (categoryId) => {
    setActiveDropdown(current => current === categoryId ? null : categoryId);
  };

  const handleFilterClick = () => {
    setIsFilterOpen(true);
  };

  if (isLoading) {
    return (
      <div className="flex gap-2">
        {[1, 2, 3].map(i => (
          <div key={i} className="animate-pulse h-10 w-32 bg-gray-200 rounded-lg" />
        ))}
      </div>
    );
  }

  if (error) {
    console.error('Error loading categories:', error);
    return <div className="text-red-500">Failed to load categories</div>;
  }

  return (
    <>
      <div className="flex justify-between items-center w-full mb-4">
        {/* Left side with filter buttons */}
        <div className="flex flex-wrap items-center gap-2">
          {categories?.map((category) => (
            <FilterDropdown
              key={category.id}
              category={category}
              selectedTab={selectedTab}
              onSelectTab={onSelectTab}
              onAddSymbol={category.id === 'personal' ? onAddSymbol : undefined}
              loggedInUser={loggedInUser}
              existingSymbols={existingSymbols}
              isOpen={activeDropdown === category.id}
              onToggle={handleDropdownToggle}
              watchlist={watchlist}
            />
          ))}
        </div>

        {/* Right side with icons */}
        <div className="flex items-center ml-auto">
          <FilterHeaderIcons 
            activeFilters={activeFilters}
            onApplyFilters={onFilterApply}
            onFilterClick={() => setIsFilterOpen(true)}
          />
        </div>
      </div>

      <SettingsDialog
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        onSave={handleSettingsSave}
        initialSettings={colorSettings}
      />
    </>
  );
};

const FilterDropdown = ({ 
  category, 
  selectedTab, 
  onSelectTab,
  onAddSymbol,
  loggedInUser,
  existingSymbols,
  isOpen,
  onToggle,
  watchlist
}) => {
  const containerRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  useClickOutside(containerRef, { current: popperElement }, () => {
    if (isOpen) {
      onToggle(null);
    }
  });

  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4], // [x, y] offset
        },
      },
    ],
  });

  const getIconName = useCallback((iconString) => {
    return iconString?.startsWith('fa-') ? iconString.slice(3) : iconString;
  }, []);

  const isPersonalCategory = category.id === 'personal';
  
  const filters = isPersonalCategory 
    ? [{ id: 'watchlist', name: 'Watch List', icon: 'fa-star' }, ...(category.filters || [])]
    : category.filters || [];

  const selectedFilter = filters.find(f => f.id === selectedTab);

  const isSelected = isPersonalCategory 
    ? selectedTab === 'watchlist' || category.filters?.some(f => f.id === selectedTab)
    : selectedTab === category.id || category.filters?.some(f => f.id === selectedTab);

  const updateDropdownPosition = () => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX
      });
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      updateDropdownPosition();
    }
  }, [isOpen]);

  return (
    <div className="inline-flex relative" ref={containerRef}>
      <button
        ref={setReferenceElement}
        onClick={() => onToggle(category.id)}
        className={`
          filter-button px-4 py-2 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200
          ${isSelected
            ? 'bg-blue-500 text-white shadow-blue-200' 
            : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'}
        `}
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <FontAwesomeIcon 
              icon={getIconName(category.icon)} 
              className="mr-2" 
            />
            <span className="dropdown-text">{category.name}</span>
          </div>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        </div>
        {isSelected && selectedFilter && (
          <div className="text-sm opacity-75 mt-1 flex items-center">
            <FontAwesomeIcon icon={getIconName(selectedFilter.icon)} className="mr-2" />
            <span>{selectedFilter.name}</span>
          </div>
        )}
      </button>

      {isPersonalCategory && (
        <AddSymbolButton
          onAddSymbol={onAddSymbol}
          existingSymbols={existingSymbols}
          isLoggedIn={!!loggedInUser}
          className="add-symbol-button rounded-lg bg-gray-100 text-gray-600 hover:bg-gray-200 focus:outline-none"
          data-tooltip="Add symbol to Watch List"
          aria-label="Add symbol to Watch List"
          usePortal={true}
        >
          +
        </AddSymbolButton>
      )}

      {isOpen && createPortal(
        <div 
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="w-56 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50 transform-gpu"
        >
          <div className="py-1">
            {filters.map((filter) => (
              <button
                key={filter.id}
                onClick={() => {
                  onSelectTab(filter.id);
                  onToggle(null);
                }}
                className={`
                  flex items-center w-full px-4 py-2 text-sm text-left
                  ${selectedTab === filter.id ? 'bg-blue-50 text-blue-700' : 'text-gray-700 hover:bg-gray-50'}
                `}
                data-tooltip={filter.description}
                aria-label={filter.description}
              >
                <FontAwesomeIcon icon={getIconName(filter.icon)} className="mr-2" />
                <span>{filter.name}</span>
              </button>
            ))}
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

FilterDropdowns.propTypes = {
  selectedTab: PropTypes.string,
  onSelectTab: PropTypes.func.isRequired,
  onAddSymbol: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object,
  existingSymbols: PropTypes.array,
  activeFilters: PropTypes.object,
  onFilterApply: PropTypes.func.isRequired,
  watchlist: PropTypes.array
};

export default FilterDropdowns; 